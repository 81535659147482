import { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { Avatar, Divider, FormLabel, ListItemIcon, Select, SelectChangeEvent, SvgIcon } from '@mui/material';
import { ReactComponent as SettingsIcon } from '../icons/settings.svg';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/useAuth';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { ReactComponent as AirlineIcon } from '../icons/aircraft--left.svg';
import { AIRLINE, CLIENT_CODE, STORAGE_KEY__AIRLINE, STORAGE_KEY__CLIENT_CODE } from '../common/constants';
import { useLocalStorage } from 'usehooks-ts';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { HOME } from '../common/paths';
import { useFetch } from '../hooks/useFetch';
import { SourceMarketFields } from '@aviation/catering-masterdata-sdk';

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    const nameParts = (name || 'Anonymous').split(' ');
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: 32,
            height: 32,
            mr: 1,
        },
        children: nameParts.length > 1 ? (`${name.split(' ')[0][0].toLocaleUpperCase()}${name.split(' ')[1][0].toLocaleUpperCase()}`) : nameParts[0][0].toLocaleUpperCase(),
    };
}

function SettingsMenu() {
    const [anchorElSettings, setAnchorElSettings] = useState<HTMLElement>();
    const { t } = useTranslation();
    const auth = useAuth();
    const [userName, setUserName] = useState<string>();
    const { clientCode, airline } = useParams();
    const [defaultAirline] = useLocalStorage(STORAGE_KEY__AIRLINE, AIRLINE);
    const [defaultClientCode, setDefaultClientCode] = useLocalStorage(STORAGE_KEY__CLIENT_CODE, CLIENT_CODE);
    const navigate = useNavigate();
    const location = useLocation();

    const { data: clientList = [] } = useFetch<Array<SourceMarketFields>>(`/api/masterdata/sourcemarkets`);

    // const handleAirlineChange = (event: SelectChangeEvent) => {
    //     setDefaultAirline(event.target.value);
    //     const newPath = generatePath(HOME, { airline: event.target.value, clientCode: clientCode || defaultClientCode });
    //     console.info('default airline has been changed... navigating to:', newPath)
    //     navigate(newPath, { state: location });
    // };

    const handleClientCodeChange = (event: SelectChangeEvent) => {
        setDefaultClientCode(event.target.value);
        const newPath = generatePath(HOME, { airline: airline || defaultAirline, clientCode: event.target.value });
        console.info('default client code has been changed... navigating to:', newPath)
        navigate(newPath, { state: location });

        let clientName = clientList.filter(o => o.ClientCode === event.target.value)[0].ClientName ?? 'Unkown';
        auth.setClient(event.target.value, clientName);
    };

    useEffect(() => {
        const loadUserData = async () => {
            if (!auth.isAuthenticated) {
                setUserName(undefined);
                setAnchorElSettings(undefined);
            } else {
                const user = await auth.getUser();
                setUserName(user?.attributes?.name || user?.attributes?.email);
            }
        };

        loadUserData();
    }, [auth]);


    useEffect(() => {
        if(clientList === undefined)
            return;

        let clients = clientList.filter(o => o.ClientCode === clientCode);

        if((clients?.length ?? 0) === 0)
            return;

        let clientName = clients[0].ClientName ?? 'Unkown';
        auth.setClient(clientCode ?? '', clientName);
    }, [clientList, clientCode, auth])

    const handleOpenSettingsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElSettings(event.currentTarget);
    };

    const handleCloseSettingsMenu = () => {
        setAnchorElSettings(undefined);
    };

    return (
        userName ?
            <>
                <Tooltip title={t('Open settings')}>
                    <IconButton onClick={handleOpenSettingsMenu} sx={{ p: 0, mr: 0.5 }}>
                        <SvgIcon component={SettingsIcon} inheritViewBox />
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElSettings}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElSettings)}
                    onClose={handleCloseSettingsMenu}
                >
                    <MenuItem style={{ pointerEvents: 'none' }}>
                        <Avatar {...stringAvatar(userName)} /> {userName}
                    </MenuItem>
                    <Divider />
                    <MenuItem style={{ pointerEvents: 'none' }}>
                        <ListItemIcon>
                            <SvgIcon component={AirlineIcon} inheritViewBox />
                        </ListItemIcon>
                        <FormLabel sx={{ marginRight: 2 }} id="client-select">{`${t('Source Market')}:`}</FormLabel>
                        <Select
                            style={{ pointerEvents: 'all' }}
                            labelId="client-select"
                            value={clientCode || defaultClientCode}
                            onChange={handleClientCodeChange}
                            size='small'
                            variant='standard'
                        >
                            { clientList.map(o => (
                                <MenuItem value={o.ClientCode}>{o.ClientName}</MenuItem>
                                ))
                            }
                        </Select>
                    </MenuItem>
                    <MenuItem onClick={auth.signOut}>
                        <ListItemIcon>
                            <SvgIcon component={LogoutIcon} inheritViewBox />
                        </ListItemIcon>
                        {t('Sign Out')}
                    </MenuItem>
                </Menu>
            </> :
            null
    );
}

export default SettingsMenu