import { useState, useMemo, useCallback, useEffect } from 'react'
import { API } from 'aws-amplify';
import { Drawer, Box, SvgIcon, IconButton, CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
// import { API } from 'aws-amplify'
import { DataGridPremium, GridColDef, GridRowModesModel, GridRowModes, GridRowParams, useGridApiRef, GridEventListener, GridRowEditStopReasons, GridRowModel, GridCallbackDetails } from '@mui/x-data-grid-premium';
import { DynamoDbObject } from '@aviation/catering-common';
import { SourceMarketFields, SourceMarketMapping } from '@aviation/catering-masterdata-sdk';
import StyledBox from '../../common/Components/StyledBox'
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { toast } from "react-toastify";
import BoxHeader from '../../common/Components/BoxHeader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { ulid } from 'ulidx';

dayjs.extend(utc)

export interface ISourceMarketsAirlineListProps {
    open: boolean;
    clientCode : string;
    parent: SourceMarketFields & DynamoDbObject
    cancelAction: () => void;
}

function rowId(item : SourceMarketMapping) {
    return item.Id;
}

function SourceMarketsAirlineList(props : ISourceMarketsAirlineListProps) {
    const apiRef = useGridApiRef();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<any>(undefined);
    const [airlineList, setAirlineList] = useState<SourceMarketMapping[]>([]);

    useEffect(() => {
        let list = props.parent.Airlines ?? [];
        setAirlineList(list);
    }, [props.parent]);

    const rows = useMemo(() => {
        const result = airlineList.map((entry: SourceMarketMapping) => {
            return {
                ...entry,
                id: rowId(entry),
                isNew: (entry as any).isNew ?? false,
            }
        }) ?? [];
        return result;
        // eslint-disable-next-line
    }, [airlineList])

    const confirmDeletion = (item : any) => {
        setDeleteItem(item);
        setShowDeleteConfirmation(true);
    };

    const handleCloseDialog = () => {
        setDeleteItem(undefined);
        setShowDeleteConfirmation(false);
    };

    const handleSaveClick = (item : any) => () => {
        setRowModesModel({...rowModesModel, [item.id ?? '']: {mode: GridRowModes.View }}); 
    };

    const cancel = () => {
        props.cancelAction();

        let ids = apiRef.current.getAllRowIds();
        ids.forEach(o => {
            if(rowModesModel[o] !== undefined) {
                handleCancelClick(o);
            }
        });
    }
    
    const handleCancelClick = (id : any) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
          });

        const editedRow = rows.find((row : any) => row.id === id);
        if (editedRow!.isNew) {
            // const rowList = props.parent.Airlines?.filter((o : any) => o.Id !== id) ?? [];
            //props.parent.Airlines = rowList;
            const rowList = airlineList.filter((o : any) => o.Id !== id) ?? [];
            setAirlineList(rowList);
        } 
      };

    const handleRowModesModelChange = useCallback((model: GridRowModesModel, details: GridCallbackDetails) => {
        setRowModesModel(model);
        // eslint-disable-next-line
      }, [airlineList]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
        if(params.reason === GridRowEditStopReasons.rowFocusOut)
            event.defaultMuiPrevented = true;
        else 
            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
        // eslint-disable-next-line
    }, [airlineList]);

    const processRowUpdate = useCallback((row: GridRowModel) => {
        const updatedRow = { ...row, isNew: false, IsLoading: true };

        updateItem(row as SourceMarketMapping & DynamoDbObject).then(success => {
            apiRef.current.updateRows([{ id: rowId(row), IsLoading: false }]);

            if(success) 
                toast.success(`Changes to source market saved successfully.`);
            else
                toast.error(`An error occurred while saving source market`);
        })
        
        return updatedRow;
        // eslint-disable-next-line
    }, [airlineList]);

    const updateItem = async(item : any) => {
        if(!item)
            return;

        // Update parent item as well
        props.parent.Airlines = [...airlineList.filter(o => o.Id !== item.Id), item];

        let payload = props.parent;
        
        payload.Airlines?.forEach(o => {
            delete (o as any).isNew
            delete (o as any).id
        });
        
        const init = {
            body: payload,
            headers: {}
        };

        try {
            const response = await API.put('api', `/api/masterdata/sourcemarkets/${props.clientCode}/`, init) as SourceMarketMapping;
            return response;
        } catch(e) {
            console.error(e);
            return undefined;
        } 
    }

    const handleDeleteItem = async () => {
        setShowDeleteConfirmation(false);

        if(deleteItem !== undefined) {
            const newList = airlineList.filter(o => o.Id !== deleteItem.Id);
            apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: true }]);

            try {
                setAirlineList(newList);
                props.parent.Airlines = newList;

                const init = {
                    body: props.parent,
                    headers: {}
                };

                const response = await API.put('api', `/api/masterdata/sourcemarkets/${props.clientCode}/`, init) as SourceMarketMapping;
                toast.success(`Source market deleted successfully.`);
                apiRef.current.updateRows([{ id: rowId(deleteItem), _action: 'delete' }]);
                
                return response;
            } catch(e) {
                console.error(e);
                apiRef.current.updateRows([{ id: rowId(deleteItem), IsLoading: false }]);
                toast.error(`An error occurred while deleting subscription`);
                return undefined;
            } 
        }
    };

    const columns: GridColDef[] = [
        { field: 'Airline', headerName: 'Code', minWidth: 200, editable: true },
        { field: 'actions', type: 'actions', headerName: '', minWidth: 55, renderCell: ({ row }: Partial<GridRowParams>) => {
            const isInEditMode = (rowModesModel[row.id]?.mode ?? GridRowModes.View) === GridRowModes.Edit;
            const isLoading = row.IsLoading ?? false;

            if(isInEditMode) 
                return [
                    <IconButton color="success" onClick={handleSaveClick(row)}>
                        <SvgIcon component={CheckmarkIcon} inheritViewBox />
                    </IconButton>,
                    <IconButton color="error" onClick={handleCancelClick(row.id)}>
                        <SvgIcon component={CrossIcon} inheritViewBox />
                    </IconButton>
                ]
            else if(!isInEditMode && !isLoading)
                return ( 
                    <IconButton color="error" onClick={() => confirmDeletion(row)}>
                        <SvgIcon component={TrashIcon} inheritViewBox />
                    </IconButton>
                )
            else if(isLoading) 
                return (
                    <CircularProgress size={19} />
                )
            }
        }
    ];

    const createItem = async () => {
        const id = ulid().toString();
        const row = {
            Id: id,
            Airline: '',
            isNew: true
        } as SourceMarketMapping;
        
        //props.parent.Entries = [...props.parent.Entries!, row];
        const list = [...(airlineList ?? []), row];
        setAirlineList(list);
        setRowModesModel((oldModel) => ({...oldModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: 'Airline' } }));
    }

    return (
        <Drawer anchor='right' open={props.open} onClose={() => cancel()} style={{maxWidth:'50%'}}>
            <Box style={{ marginTop: 70, paddingLeft: 20, paddingRight:20 }}>
                <BoxHeader title={'Airlines'}>
                    <Button variant="contained" onClick={createItem}>Create</Button>
                </BoxHeader>
                <p>All assigned airlines will be associated to this source market, <br/>when imported from IDPS. Please prevent <br/>duplicates across source markets.</p>
            </Box>
            
            <StyledBox style={{paddingLeft:20, paddingRight:20}}>
                <DataGridPremium
                    apiRef={apiRef}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    getRowClassName={(params) => { return params.indexRelativeToCurrentPage % 2 === 0 ? `tui-grid-alternate-row` : ''}}
                    // pagination
                    isCellEditable={(params) => true}

                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                />
            </StyledBox>
            <Dialog open={showDeleteConfirmation} onClose={handleCloseDialog}>
                        <DialogTitle id="alert-dialog-title">
                            {"Delete subscription?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete this airline? You can't undo this action.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} autoFocus>Cancel</Button>
                            <Button onClick={handleDeleteItem} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
        </Drawer>
    )
}

export default SourceMarketsAirlineList